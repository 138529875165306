import React from "react";

// local imports

import { ArrowsMobileVersion } from "../../Svgs/DividerIcons/ArrowsMobileVersion";
import { ArrowsMobileVersion2 } from "../../Svgs/DividerIcons/ArrowsMobileVersion2";
import { ArrowsTabletVersion1 } from "../../Svgs/DividerIcons/ArrowsTabletVersion1";
import { ArrowsTabletVersion2 } from "../../Svgs/DividerIcons/ArrowsTabletVersion2";
import { ArrowsTabletVersion3 } from "../../Svgs/DividerIcons/ArrowsTabletVersion3";
import { ArrowsDesktopVersion } from "../../Svgs/DividerIcons/ArrowsDesktopVersion";
import { ArrowsDesktopVersion2 } from "../../Svgs/DividerIcons/ArrowsDesktopVersion2";
import { IconsDesktop } from "../../Svgs/DividerIcons/IconsDesktop";
import { IconsMobile } from "../../Svgs/DividerIcons/IconsMobile";
import { IconsTablet } from "../../Svgs/DividerIcons/IconsTablet";

export const FirstDivider = () => {
  return (
    <>
      <div
        id="about-us"
        className="flex flex-col justify-start 5xs:h-[475px] xxs:h-[750px] xxs:mb-6  xxs:w-full md2:w-full 5xs:mb-1 4xs:mb-0 3xs:mb-1 5xs:pb-8"
      >
        {/* divider image element */}
        <div className="absolute 5xs:h-60 xxs:h-[563px]">
          {/* icons */}

          <div className="5xs:ml-14 5xs:mt-14 xxs:ml-24 xxs:mt-24 sm:ml-44 md2:ml-96 md2:mt-24">
            {/* Mobile version All smartphones */}
            <div className="block xxs:hidden sm:hidden md:hidden lg:hidden">
              <IconsMobile />
            </div>
            {/* ipad Mini Version 768px */}
            <div className="hidden xxs:block xxs2:hidden sm:hidden md:hidden lg:hidden">
              <IconsTablet />
            </div>

            {/* ipad Air Version 820px */}
            <div className="hidden xxs:hidden xxs2:block sm:hidden md:hidden lg:hidden">
              <IconsTablet />
            </div>
            {/* ipad Pro Version 1024px */}
            <div className="hidden sm:block sm2:hidden md:hidden lg:hidden">
              <IconsDesktop />
            </div>

            {/* Nest Hub Max 1280px */}
            <div className="hidden sm:hidden sm2:block md:hidden lg:hidden">
              <IconsDesktop />
            </div>

            {/* small desktops 1300px */}
            <div className="hidden sm:hidden sm2:hidden md:block lg:hidden">
              <IconsDesktop />
            </div>

            {/* Large Desktops 1440px +  */}
            <div className="hidden sm:hidden md:hidden lg:block">
              <IconsDesktop />
            </div>
          </div>

          {/* end of icons */}

          {/* Arrows */}
          <div className="absolute 5xs:w-full 5xs:h-[219.46px] 5xs:top-[29.03px] ">
            {/* Mobile version  < 4xs */}
            <div className="hidden 5xs:block 4xs:hidden xxs:hidden sm:hidden md:hidden lg:hidden">
              <ArrowsMobileVersion2 />
            </div>
            {/* Mobile version > 4xs   */}
            <div className="hidden 5xs:hidden 4xs:block xxs:hidden sm:hidden md:hidden lg:hidden">
              <ArrowsMobileVersion />
            </div>
            {/* ipad Mini Version 768px */}
            <div className="hidden xxs:block xxs2:hidden sm:hidden md:hidden lg:hidden">
              <ArrowsTabletVersion1 />
            </div>

            {/* ipad Air Version 820px */}
            <div className="hidden xxs:hidden xxs2:block sm:hidden md:hidden lg:hidden">
              <ArrowsTabletVersion3 />
            </div>
            {/* ipad Pro Version 1024px */}
            <div className="hidden sm:block sm2:hidden md:hidden lg:hidden">
              <ArrowsTabletVersion2 />
            </div>

            {/* Nest Hub Max 1280px */}
            <div className="hidden sm:hidden sm2:block md:hidden lg:hidden">
              <ArrowsDesktopVersion2 />
            </div>

            {/* small desktops 1300px */}
            <div className="hidden sm:hidden sm2:hidden md:block lg:hidden">
              <ArrowsDesktopVersion />
            </div>

            {/* Large Desktops 1440px +  */}
            <div className="hidden sm:hidden md:hidden lg:block">
              <ArrowsDesktopVersion />
            </div>

            {/* Headline */}
            <div className="flex flex-col justify-start 5xs:py-10 5xs:w-[300px] 5xs:w-[300px] xxs:w-[630px] xxs:w-[620px]  md2:w-[770px] 5xs:h-[194px]">
              <div className="mx-auto">
                <h1 className="font-Montserrat font-black 5xs:text-4xl xxs:text-[84px] xxs:mb-3 tracking-tight uppercase text-blue-700">
                  Perspektive
                </h1>
                <h2 className="font-Montserrat font-light 5xs:text-4xl xxs:text-[84px] tracking-tight uppercase text-yellow-500 5xs:leading-[37.6px] xxs:leading-[80%]">
                  erzeugt <br /> Lösungen
                </h2>
              </div>
            </div>
            {/* end of headline  */}
          </div>
          {/* end of arrows Mobile version */}
        </div>
      </div>
    </>
  );
};
